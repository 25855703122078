<template>
  <div :id="prefixId + '-gis2dViewer'" class="gis2DViewer" ref="gis">
    <div :id="prefixId + '-viewDiv'" class="viewDiv2D"></div>
    <!-- <div :id="prefixId + '-elevationDiv'" class="elevationDiv esri-widget">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" :id="prefixId + '-elevationInput'" v-on:change="updateElevation" checked="yes">
        <input type="checkbox" class="custom-control-input" :id="prefixId + '-elevationInput'" checked="yes" v-on:change="updateElevation">
        <label class="custom-control-label" :for="prefixId + '-elevationInput'">Elevation</label>
      </div>
    </div> -->
    <!-- <div :id="prefixId+'-screenshotBtnDiv'" class="screenshotBtnDiv esri-widget">
      Start to screenshot by clicking the button below:
      <br>
      <br>
      <button
        :id="prefixId + '-screenshotBtn'"
        class="esri-button"
        aria-label="Select screenshot area"
        title="Select screenshot area"
        @click="screenshotBtnClick">
        Capture
      </button>
    </div> -->
    <div :id="prefixId+'-screenshotDiv'" class="screenshotDiv hide">
      <img :id="prefixId+'-screenshotImage'" class="js-screenshot-image" />
      <div>
        <label>Set a text to be displayed on the image: </label>
        <input type="text" placeholder="Image text" :id="prefixId+'-textInput'" autofocus />
      </div>
      <button
        :id="prefixId+'-downloadBtn'"
        class="btn btn-primary"
        aria-label="Download image"
        title="Download image">
        Download image
      </button>
      <button
        :id="prefixId+'-closeBtn'"
        class="btn btn-secondary"
        aria-label="Back to webscene"
        title="Back to webscene"
        @click="screenshotCloseClick">
        Back to webscene
      </button>
    </div>
    <div :id="prefixId+'-maskDiv'" class="hide maskDiv screenshotCursor"></div>
    <div :id="prefixId+'-fullscreenDiv'" class="fullscreenDiv"></div>
    <div :id="prefixId+'-environmentDiv'" class="environmentDiv esri-widget" >
      <div class="row">
        <div class="col-4">
          <label>Time&nbsp;of&nbsp;Day</label>
        </div>
        <br>
        <br>
        <div class="col-8">
          <select :id="prefixId+'-timeOfDaySelect'" class="form-control form-control-sm" v-on:change="updateTimeOfDay">
            <option value="1">Morning</option>
            <option value="2">Noon</option>
            <option value="3">Afternoon</option>
            <option value="4">Evening</option>
          </select>
        </div>
      </div>
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" :id="prefixId+'-directShadowsInput'" checked="yes" v-on:change="updateDirectShadows">
        <label class="custom-control-label" for="directShadowsInput">Show Shadows</label>
      </div>
    </div>
    <div :id="prefixId+'-opacitySliderDiv'" class="opacitySliderDiv esri-widget">
      <label><small>Ground Transparency</small></label>
      <div class="row container justify-content-center m-0">
        <div class="col-sm-auto p-0">
          <small>0</small>
        </div>
        <div class="col-sm-auto pl-2 pr-2">
          <input type="range" min="0" max="100" step="1" v-model="opacityValue" @change="setOpacity" class="form-range">
        </div>
        <div class="col-sm-auto p-0">
          <small>100</small>
        </div>
      </div>
    </div>
    <!-- <div :id="prefixId+'-discussionDiv'" class="discussionDiv esri-widget" >
      <NewDiscussion :mapContainer="prefixId + '-gis2dViewer'"></NewDiscussion>
    </div> -->
    <button :id="prefixId+'-discussionToolFullView'"  @click="openDiscussionToolFullView()" class="btn btn-dark esri-widget discussionToolFullView" title="See Discussions"><i class="fa fa-comments"></i></button>
    <button :id="prefixId+'-discussionToolRefresh'"  @click="refreshDiscussions()" class="btn btn-dark esri-widget discussionToolRefresh" title="Refresh Discussions"><i class="fa fa-sync-alt"></i></button>
    <button :id="prefixId+'-FAQButton'"  @click="modalfaqShow = true" class="btn btn-dark esri-widget custom-faq-widget" title="See FAQ"><i class="fa fa-question-circle"></i></button>
    <div :id="prefixId+'-slidesDiv'" class="slidesDiv"></div>

    <b-modal v-model="modalfaqShow" size="lg">
      <!-- <img class="img-fluid" src="sampleImage.jpg" /> -->
      <iframe
        src="./GWH_2DWebPortalUserGuide.pdf"
        width="100%"
        height="700"
        style="border: none; overflow: hidden;"
      />
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="modalfaqShow=false"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- Sidebar Gallery -->
    <b-button
      :id="prefixId + '-viewGalleryDiv'"
      class="btn btn-dark esri-widget custom-faq-widget"
      title="View Gallery" v-b-toggle.sidebar-right>
      <i class="fa fa-images"></i>
    </b-button>
    <b-sidebar id="sidebar-right" title="Report and Attachments" width="500px" right shadow>
      <br>
      <div class="row" v-if="reportURL!=''">
        <div class="col-1">
          <a :href="reportURL" target="_blank" class="col-3">
            <img class="fa fa-download">
          </a>
        </div>
        <div class="col-6">
          <h5>REPORT download</h5>
        </div>
      </div>
      <div v-for="(entry, iter) in attachmentPhotos.filter(item=>item.attachmentURLs.length>0)" :key="iter" class="px-3 py-2">
        <!-- <LightGallery
          :images="entry.attachmentURLs"
          :index="entry.imageIndex"
          :disable-scroll="true"
          @close="entry.imageIndex = null"
        /> -->
        <CoolLightBox
          :items="entry.attachmentURLs"
          :index="entry.imageIndex"
          :useZoomBar="true"
          @close="entry.imageIndex = null">
        </CoolLightBox>
        <h5>
          <b>
            {{entry.title}}
          </b>
        </h5>
        <ul class="ul-thumb">
          <li
            class="thumb"
            v-for="(thumb, thumbIndex) in entry.attachmentURLs"
            :key="thumbIndex"
            @click="entry.imageIndex = thumbIndex"
          >
            <img :src="thumb.src" height="140px" width="140px">
          </li>
        </ul>
      </div>
    </b-sidebar>
    <!-- Sidebar Gallery -->
  </div>

</template>
<script>
/*eslint-disable*/
  import WebScene from '@arcgis/core/WebScene';
  import SceneView from '@arcgis/core/views/SceneView';
  import WebMap from '@arcgis/core/WebMap';
  import MapView from '@arcgis/core/views/MapView';
  import esriConfig from '@arcgis/core/config';
  import esriId from "@arcgis/core/identity/IdentityManager";
  import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
  import Portal from '@arcgis/core/portal/Portal';
  import PortalItem from '@arcgis/core/portal/PortalItem';
  import Expand from "@arcgis/core/widgets/Expand";
  import Fullscreen from "@arcgis/core/widgets/Fullscreen";
  import Home from "@arcgis/core/widgets/Home";
  import Search from "@arcgis/core/widgets/Search";
  import LayerList from "@arcgis/core/widgets/LayerList";
  import Legend from "@arcgis/core/widgets/Legend";
  import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
  import '@arcgis/core/assets/esri/themes/dark/main.css';
  // import NewDiscussion from './CommentingTool/NewDiscussion.vue';
  import {baseApiUrl, appBaseUrl} from "../../config.js";
  import { ClassBreaksRenderer, UniqueValueRenderer, RasterColormapRenderer, RasterStretchRenderer, RasterShadedReliefRenderer, VectorFieldRenderer } from "@arcgis/core/rasterRenderers";
  import ImageryTileLayer from "@arcgis/core/layers/ImageryTileLayer";
  import TileLayer from "@arcgis/core/layers/TileLayer";
  import ImageryLayer from "@arcgis/core/layers/ImageryLayer";
  import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
  // import { LightGallery } from 'vue-light-gallery';
  import CoolLightBox from 'vue-cool-lightbox'
  import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
  // import AttachmentQuery from "@arcgis/core/rest/support/AttachmentQuery";
  import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
  import Bookmarks from "@arcgis/core/widgets/Bookmarks";
  import axios from 'axios'
  export default {
    name: 'GIS2DViewer',
    props: {
      appId: String,
      webMapId: String
    },
    components: {
      // NewDiscussion,
      // LightGallery,
      CoolLightBox
    },
    data() {
      return {
        prefixId: null,
        portalURL: "https://au1gis.solutions.arcadis.com/arcgis",
        webMap: null,
        view: null,
        search: null,
        expandSearch: null,
        expandLayerList: null,
        layerList: null,
        legendList: null,
        expandLegend: null,
        baseMapGallery: null,
        expandBaseMapGallery: null,
        slice: null,
        expandSlice: null,
        expandHandleSlice: null,
        measurementLineWidget: null,
        measurementAreaWidget: null,
        expandMeasurementLine: null,
        expandMeasurementArea: null,
        expandHandleMeasurementLine: null,
        expandHandleMeasurementArea: null,
        screenshotBtnDiv: null,
        expandScreenshot: null,
        fullscreenDiv: null,
        fullscreen: null,
        elevationWidget: null,
        faqWidget: null,
        discussionToolWidget: null,
        discussionToolRefreshWidget: null,
        lightingWidget: null,
        expandLighting: null,
        discussionWidget: null,
        expandDiscussionTool: null,
        expandSlide: null,
        expandHandleSlide: null,
        slides: null,
        clientCanvas: {
          height: "",
          width: ""
        },
        fullHeight: 0,
        modalfaqShow: false,
        discussionLayer: null,
        opacityValue: 50,
        opacitySliderContainer: null,
        expandOpacitySliderWidget: null,
        images: [
          { title:'img 1', url: 'ApprovedMarker.png' },
          { title:'img 2', url: 'InitialMarker.png' },
          { title:'img 3', url: 'https://au1gis.solutions.arcadis.com/arcgis/rest/services/Hosted/service_1b25380d736245fca423df4e867cb730/FeatureServer/2/4/attachments/26?token=nsleoA938uZ1-OUvu3MXWdNJPYRXEi1GiJyhvK-tl3I5RJI2xa7N1VnogbTjs2hyjRUflyU_vAZhNFDP5-v71KgQjswxbpBhkop_pa5ClBm86wroDiJOHdL9a-5IZlzKrYUBLSn0YnXvdMP2tFVBU3brSRqZ7eIFmh2Eta8NhZ0d2vyba3ZhCaVN5BOTWZJfJQ2LZeeMMmD0zfmjUklS4V7UBiqjzXDnpvitgZLkcDhzak4skgKN33wyEdtyGjsuJX06nvRuZom-T0-NmnqR4aI9eFMwZyhmekeEWjBtFrI.' },
          { title:'img 4', url: 'https://www.shaadidukaan.com/vogue/wp-content/uploads/2019/08/hug-kiss-images.jpg' }
        ],
        attachmentPhotos: [],
        pilotAttachmentPhotos: [
          {
            title: "Ampol Pilot Electrical Checklist",
            tableName: "Site_Audit_Checklist",
            label: "",
            attachmentURLs: [],
            index: 0,
            imageIndex: null
          },
          {
            title: "Site markup",
            tableName: "repeat_site_markup",
            label: "",
            attachmentURLs: [],
            index: 1,
            imageIndex: null
          },
          {
            title: "Photos of incoming supply arrangement",
            tableName: "repeat_photo_supplyarrang",
            label: "",
            attachmentURLs: [],
            index: 2,
            imageIndex: null
          },
          {
            title: "",
            tableName: "repeat_photo_itemmarkup",
            label: "",
            attachmentURLs: [],
            index: 3,
            imageIndex: null
          },
          {
            title: "",
            tableName: "repeat_meter_numbers",
            label: "",
            attachmentURLs: [],
            index: 4,
            imageIndex: null
          },
          {
            title: "Meter photos",
            tableName: "repeat_meter_photo",
            label: "",
            attachmentURLs: [],
            index: 5,
            imageIndex: null
          },
          {
            title: "Photos of service mains arrangement",
            tableName: "repeat_sm_arrangement",
            label: "",
            attachmentURLs: [],
            index: 6,
            imageIndex: null
          },
          {
            title: "Inspect Consumer Mains",
            tableName: "consumer_mains",
            label: "",
            attachmentURLs: [],
            index: 7,
            imageIndex: null
          },
          {
            title: "Photos of internal arrangement of switchboard",
            tableName: "repeat_switchb_intern_arrang",
            label: "",
            attachmentURLs: [],
            index: 8,
            imageIndex: null
          },
          {
            title: "Space around the switchboard",
            tableName: "repeat_markup_switchb_space",
            label: "",
            attachmentURLs: [],
            index: 9,
            imageIndex: null
          },
          {
            title: "Items near the switchboard",
            tableName: "repeat_nearby_switchb",
            label: "",
            attachmentURLs: [],
            index: 10,
            imageIndex: null
          },
          {
            title: "Photos of switchboard",
            tableName: "repeat_photo_switchboard",
            label: "",
            attachmentURLs: [],
            index: 11,
            imageIndex: null
          },
          {
            title: "Photos of information on/in the switchboard",
            tableName: "repeat_photo_switchb_dwgs",
            label: "",
            attachmentURLs: [],
            index: 12,
            imageIndex: null
          },
          {
            title: "Photos for AS3000-2018 compliance",
            tableName: "repeat_photos_as3000",
            label: "",
            attachmentURLs: [],
            index: 13,
            imageIndex: null
          },
          {
            title: "Mark up and photos of hazardous zones",
            tableName: "repeat_hazardous_zones",
            label: "",
            attachmentURLs: [],
            index: 14,
            imageIndex: null
          },
          {
            title: "Temporary items - talk to site staff",
            tableName: "repeat_temporaryitems",
            label: "",
            attachmentURLs: [],
            index: 15,
            imageIndex: null
          },
          {
            title: "Proposed Equipment Locations",
            tableName: "repeat_proposed_equipment_loc",
            label: "",
            attachmentURLs: [],
            index: 16,
            imageIndex: null
          },
          {
            title: "Photos of proposed EV equipment",
            tableName: "repeat_photos_equipment",
            label: "",
            attachmentURLs: [],
            index: 17,
            imageIndex: null
          },
          {
            title: "Earthing photos",
            tableName: "repeat_earthing_photo",
            label: "",
            attachmentURLs: [],
            index: 18,
            imageIndex: null
          },
          {
            title: "Potential trench routes",
            tableName: "repeat_trench_route",
            label: "",
            attachmentURLs: [],
            index: 19,
            imageIndex: null
          },
          {
            title: "MSB Site",
            tableName: "msb_site",
            label: "",
            attachmentURLs: [],
            index: 20,
            imageIndex: null
          },
          {
            title: "",
            tableName: "repeat_network_works",
            label: "",
            attachmentURLs: [],
            index: 21,
            imageIndex: null
          },
          {
            title: "Network Works Mark Up",
            tableName: "repeat_nw_works",
            label: "",
            attachmentURLs: [],
            index: 22,
            imageIndex: null
          },
          {
            title: "Potential Substation Locations",
            tableName: "repeat_substationlocations",
            label: "",
            attachmentURLs: [],
            index: 23,
            imageIndex: null
          },
          {
            title: "",
            tableName: "additional_items",
            label: "",
            attachmentURLs: [],
            index: 24,
            imageIndex: null
          },
          {
            title: "Ratings of sockets > 10A",
            tableName: "repeat_socket_ratings",
            label: "",
            attachmentURLs: [],
            index: 25,
            imageIndex: null
          },
          {
            title: "Appliances",
            tableName: "repeat_appliances",
            label: "",
            attachmentURLs: [],
            index: 26,
            imageIndex: null
          },
          {
            title: "EV charger ratings",
            tableName: "repeat_ev_charger_ratings",
            label: "",
            attachmentURLs: [],
            index: 27,
            imageIndex: null
          },
          {
            title: "Motor rating",
            tableName: "repeat_motor_ratings",
            label: "",
            attachmentURLs: [],
            index: 28,
            imageIndex: null
          },
          {
            title: "Lift rating",
            tableName: "repeat_lift_ratings",
            label: "",
            attachmentURLs: [],
            index: 29,
            imageIndex: null
          },
          {
            title: "Fuel dispensing unit ratings",
            tableName: "repeat_fdue_ratings",
            label: "",
            attachmentURLs: [],
            index: 30,
            imageIndex: null
          },
          {
            title: "Heater ratings",
            tableName: "repeat_heater_ratings",
            label: "",
            attachmentURLs: [],
            index: 31,
            imageIndex: null
          },
          {
            title: "Welding machine ratings",
            tableName: "repeat_weld_ratings",
            label: "",
            attachmentURLs: [],
            index: 32,
            imageIndex: null
          },
          {
            title: "Data rack",
            tableName: "repeat_data_rack",
            label: "",
            attachmentURLs: [],
            index: 33,
            imageIndex: null
          },
          {
            title: "360 degree site photos",
            tableName: "repeat_site_photos",
            label: "",
            attachmentURLs: [],
            index: 34,
            imageIndex: null
          },
        ],
        tranche1AttachmentPhotos: [
          {
            title: "Ampol Tranche 1 Electrical Checklist",
            tableName: "tranche1_electrical_checklist",
            label: "",
            attachmentURLs: [],
            index: 0,
            imageIndex: null
          },
          {
            title: "Existing supply arrangement: site markup",
            tableName: "repeat_site_markup",
            label: "",
            attachmentURLs: [],
            index: 1,
            imageIndex: null
          },
          {
            title: "Photos of incoming supply arrangement",
            tableName: "repeat_photo_supplyarrang",
            label: "",
            attachmentURLs: [],
            index: 2,
            imageIndex: null
          },
          {
            title: "Photos of service mains arrangement",
            tableName: "repeat_sm_arrangement",
            label: "",
            attachmentURLs: [],
            index: 3,
            imageIndex: null
          },
          {
            title: "Photos of internal arrangement of switchboard",
            tableName: "repeat_switchb_intern_arrang",
            label: "",
            attachmentURLs: [],
            index: 4,
            imageIndex: null
          },
          {
            title: "Switchboard location markup",
            tableName: "repeat_switchb_location",
            label: "",
            attachmentURLs: [],
            index: 5,
            imageIndex: null
          },
          {
            title: "Space around the switchboard markup",
            tableName: "repeat_markup_switchb_space",
            label: "",
            attachmentURLs: [],
            index: 6,
            imageIndex: null
          },
          {
            title: "Items near the switchboard",
            tableName: "repeat_nearby_switchb",
            label: "",
            attachmentURLs: [],
            index: 7,
            imageIndex: null
          },
          {
            title: "Photos of switchboard",
            tableName: "repeat_photo_switchboard",
            label: "",
            attachmentURLs: [],
            index: 8,
            imageIndex: null
          },
          {
            title: "Photos of information on/in the switchboard",
            tableName: "repeat_photo_switchb_dwgs",
            label: "",
            attachmentURLs: [],
            index: 9,
            imageIndex: null
          },
          {
            title: "Photos for AS3000-2018 compliance",
            tableName: "repeat_photos_as3000",
            label: "",
            attachmentURLs: [],
            index: 10,
            imageIndex: null
          },
          {
            title: "Meter photos",
            tableName: "repeat_meter_photo",
            label: "",
            attachmentURLs: [],
            index: 11,
            imageIndex: null
          },
          {
            title: "Consumer mains arrangement",
            tableName: "consumer_mains",
            label: "",
            attachmentURLs: [],
            index: 12,
            imageIndex: null
          },
          {
            title: "Other switchboards",
            tableName: "repeat_oth_existing_switchb",
            label: "",
            attachmentURLs: [],
            index: 13,
            imageIndex: null
          },
          {
            title: "Photos of internal arrangement of other switchboard",
            tableName: "repeat_oth_switchb_internarrang",
            label: "",
            attachmentURLs: [],
            index: 14,
            imageIndex: null
          },
          {
            title: "Photos of other switchboard",
            tableName: "oth_repeat_photo_switchboard",
            label: "",
            attachmentURLs: [],
            index: 15,
            imageIndex: null
          },
          {
            title: "Photos of information on/in the other switchboard",
            tableName: "repeat_photo_oth_switchb_dwgs",
            label: "",
            attachmentURLs: [],
            index: 16,
            imageIndex: null
          },
          {
            title: "Photos for AS3000-2018 compliance of the other switchboard",
            tableName: "repeat_photos_oth_as3000",
            label: "",
            attachmentURLs: [],
            index: 17,
            imageIndex: null
          },
          {
            title: "Electrical pit location",
            tableName: "repeat_electr_pit_markup",
            label: "",
            attachmentURLs: [],
            index: 18,
            imageIndex: null
          },
          {
            title: "Photos of electrical pit (external)",
            tableName: "repeat_photo_ext_electr_pit",
            label: "",
            attachmentURLs: [],
            index: 19,
            imageIndex: null
          },
          {
            title: "Photos of electrical pit (internal)",
            tableName: "repeat_photo_int_electr_pit",
            label: "",
            attachmentURLs: [],
            index: 20,
            imageIndex: null
          },
          {
            title: "Hazardous zones",
            tableName: "repeat_hazardous_zones",
            label: "",
            attachmentURLs: [],
            index: 21,
            imageIndex: null
          },
          {
            title: "Temporary items - Talk to site staff",
            tableName: "repeat_temporaryitems",
            label: "",
            attachmentURLs: [],
            index: 22,
            imageIndex: null
          },
          {
            title: "Proposed Equipment Locations",
            tableName: "repeat_proposed_equipment_loc",
            label: "",
            attachmentURLs: [],
            index: 23,
            imageIndex: null
          },
          {
            title: "Photos of proposed equipment",
            tableName: "repeat_photos_equipment",
            label: "",
            attachmentURLs: [],
            index: 24,
            imageIndex: null
          },
          {
            title: "Markup of proposed equipment",
            tableName: "repeat_markup_propos_equip",
            label: "",
            attachmentURLs: [],
            index: 25,
            imageIndex: null
          },
          {
            title: "Photos of CCTV camera",
            tableName: "repeat_photos_cctv",
            label: "",
            attachmentURLs: [],
            index: 26,
            imageIndex: null
          },
          {
            title: "Photos of light coverage",
            tableName: "repeat_photos_lightcoverage",
            label: "",
            attachmentURLs: [],
            index: 27,
            imageIndex: null
          },
          {
            title: "Potential canopy markup",
            tableName: "repeat_markup_propos_canopy",
            label: "",
            attachmentURLs: [],
            index: 28,
            imageIndex: null
          },
          {
            title: "Line of sight photos",
            tableName: "repeat_line_of_sight",
            label: "",
            attachmentURLs: [],
            index: 29,
            imageIndex: null
          },
          {
            title: "Earthing photos",
            tableName: "repeat_earthing_photo",
            label: "",
            attachmentURLs: [],
            index: 30,
            imageIndex: null
          },
          {
            title: "Potential trench routes",
            tableName: "repeat_trench_route",
            label: "",
            attachmentURLs: [],
            index: 31,
            imageIndex: null
          },
          {
            title: "MSB Site",
            tableName: "msb_site",
            label: "",
            attachmentURLs: [],
            index: 32,
            imageIndex: null
          },
          {
            title: "Potential battery and inverter locations",
            tableName: "repeat_battery_locations",
            label: "",
            attachmentURLs: [],
            index: 33,
            imageIndex: null
          },
          {
            title: "Suggested site works mark up",
            tableName: "repeat_markup_suggestsitework",
            label: "",
            attachmentURLs: [],
            index: 34,
            imageIndex: null
          },
          {
            title: "Network works",
            tableName: "repeat_network_works",
            label: "",
            attachmentURLs: [],
            index: 35,
            imageIndex: null
          },
          {
            title: "Photos of network works",
            tableName: "repeat_nw_works",
            label: "",
            attachmentURLs: [],
            index: 36,
            imageIndex: null
          },
          {
            title: "Potential substation locations",
            tableName: "repeat_substationlocations",
            label: "",
            attachmentURLs: [],
            index: 37,
            imageIndex: null
          },
          {
            title: "Socket ratings",
            tableName: "repeat_socket_ratings",
            label: "",
            attachmentURLs: [],
            index: 38,
            imageIndex: null
          },
          {
            title: "Appliances",
            tableName: "repeat_appliances",
            label: "",
            attachmentURLs: [],
            index: 39,
            imageIndex: null
          },
          {
            title: "EV charger ratings",
            tableName: "repeat_ev_charger_ratings",
            label: "",
            attachmentURLs: [],
            index: 40,
            imageIndex: null
          },
          {
            title: "Motor ratings",
            tableName: "repeat_motor_ratings",
            label: "",
            attachmentURLs: [],
            index: 41,
            imageIndex: null
          },
          {
            title: "Lift ratings",
            tableName: "repeat_lift_ratings",
            label: "",
            attachmentURLs: [],
            index: 42,
            imageIndex: null
          },
          {
            title: "Fuel dispensing unit ratings",
            tableName: "repeat_fdue_ratings",
            label: "",
            attachmentURLs: [],
            index: 43,
            imageIndex: null
          },
          {
            title: "Heater ratings",
            tableName: "repeat_heater_ratings",
            label: "",
            attachmentURLs: [],
            index: 44,
            imageIndex: null
          },
          {
            title: "Weld ratings",
            tableName: "repeat_weld_ratings",
            label: "",
            attachmentURLs: [],
            index: 45,
            imageIndex: null
          },
          {
            title: "Photos of the patch panel arrangement",
            tableName: "repeat_data_rack",
            label: "",
            attachmentURLs: [],
            index: 46,
            imageIndex: null
          },
          {
            title: "Canopy condition",
            tableName: "repeat_canopy_condition",
            label: "",
            attachmentURLs: [],
            index: 47,
            imageIndex: null
          },
          {
            title: "360 degree site photos",
            tableName: "repeat_site_photos",
            label: "",
            attachmentURLs: [],
            index: 48,
            imageIndex: null
          },
        ],
        // tranche3AttachmentPhotos: [
        //     {
        //         title: "Ampol Tranche 3 Electrical Checklist",
        //         tableName: "AU_Ampol_Tranche3_v1",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 0,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "oth_repeat_as3000_compliance",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 2,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Photos for AS3000-2018 compliance",
        //         tableName: "repeat_as3000_compliance",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 4,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Canopy condition",
        //         tableName: "repeat_canopy_condition",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 6,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Data rack",
        //         tableName: "repeat_data_rack",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 8,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Electrical pit location",
        //         tableName: "repeat_electr_pit_markup",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 10,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Mark up and photos of hazardous zones",
        //         tableName: "repeat_hazardous_zones",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 12,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Line of sight photos",
        //         tableName: "repeat_line_of_sight",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 14,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_m_switchb_photos",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 16,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_m_switchb_tenancies",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 18,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_mains_sections",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 19,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_markup_in_m_switchb",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 21,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_markup_in_oth_switchb",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 23,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_markup_oth_switchb_space",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 25,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Space around the switchboard markup",
        //         tableName: "repeat_markup_switchb_space",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 27,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_no_substation_photos",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 29,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Other switchboards",
        //         tableName: "repeat_oth_existing_switchb",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 31,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_oth_recorded_risks",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 33,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_oth_switchb_photos",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 35,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Photos of electrical pit (external)",
        //         tableName: "repeat_photo_ext_electr_pit",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 37,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Photos of electrical pit (internal)",
        //         tableName: "repeat_photo_int_electr_pit",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 39,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Photos of incoming supply arrangement",
        //         tableName: "repeat_photo_supplyarrang",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 41,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Photos of proposed equipment",
        //         tableName: "repeat_photos_equipment",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 43,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Proposed Equipment Locations",
        //         tableName: "repeat_proposed_equipment_loc",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 45,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_recorded_risks",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 47,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Site Markup",
        //         tableName: "repeat_site_markup",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 49,
        //         imageIndex: null
        //     },
        //     {
        //         title: "360 degree site photos",
        //         tableName: "repeat_site_photos",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 51,
        //         imageIndex: null
        //     },
        //     {
        //         title: "Photos of service mains arrangement",
        //         tableName: "repeat_sm_arrangement",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 53,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_sub_mains",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 55,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_sub_mains_sections",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 56,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_sub_sm_arrangement",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 58,
        //         imageIndex: null
        //     },
        //     {
        //         title: "",
        //         tableName: "repeat_yes_substation_photos",
        //         label: "",
        //         attachmentURLs: [],
        //         index: 60,
        //         imageIndex: null
        //     },
          
        // ],
        tranche3AttachmentPhotos: [
            {
                title: "Ampol Tranche 3 Electrical Checklist",
                tableName: "AU_Ampol_Tranche3_v1",
                label: "",
                attachmentURLs: [],
                index: 0,
                imageIndex: null
            },
            {
                title: "Photos of substation",
                tableName: "repeat_no_substation_photos",
                label: "",
                attachmentURLs: [],
                index: 29,
                imageIndex: null
            },
            {
                title: "Photos of substation",
                tableName: "repeat_yes_substation_photos",
                label: "",
                attachmentURLs: [],
                index: 60,
                imageIndex: null
            },
            {
                title: "Existing supply arrangement markup",
                tableName: "repeat_site_markup",
                label: "",
                attachmentURLs: [],
                index: 49,
                imageIndex: null
            },
            {
                title: "Photos of incoming supply arrangement",
                tableName: "repeat_photo_supplyarrang",
                label: "",
                attachmentURLs: [],
                index: 41,
                imageIndex: null
            },
            {
                title: "Photos of service mains arrangement",
                tableName: "repeat_sm_arrangement",
                label: "",
                attachmentURLs: [],
                index: 53,
                imageIndex: null
            },
            {
                title: "Photos of existing main switchboard",
                tableName: "repeat_m_switchb_photos",
                label: "",
                attachmentURLs: [],
                index: 16,
                imageIndex: null
            },
            {
                title: "Space around the switchboard markup",
                tableName: "repeat_markup_switchb_space",
                label: "",
                attachmentURLs: [],
                index: 27,
                imageIndex: null
            },
            {
                title: "Photos for AS3000-2018 compliance",
                tableName: "repeat_as3000_compliance",
                label: "",
                attachmentURLs: [],
                index: 4,
                imageIndex: null
            },
            {
                title: "Photos of risks",
                tableName: "repeat_recorded_risks",
                label: "",
                attachmentURLs: [],
                index: 47,
                imageIndex: null
            },
            {
                title: "Photos of sub mains",
                tableName: "repeat_sub_mains",
                label: "",
                attachmentURLs: [],
                index: 55,
                imageIndex: null
            },
            {
                title: "Other switchboards",
                tableName: "repeat_oth_switchb_photos",
                label: "",
                attachmentURLs: [],
                index: 35,
                imageIndex: null
            },
            {
                title: "Space around switchboard markup",
                tableName: "repeat_markup_oth_switchb_space",
                label: "",
                attachmentURLs: [],
                index: 25,
                imageIndex: null
            },
            {
                title: "Available space in switchboard markup",
                tableName: "repeat_markup_in_oth_switchb",
                label: "",
                attachmentURLs: [],
                index: 23,
                imageIndex: null
            },
            {
                title: "AS3000-2018 compliance",
                tableName: "oth_repeat_as3000_compliance",
                label: "",
                attachmentURLs: [],
                index: 2,
                imageIndex: null
            },
            {
                title: "Photos of risks",
                tableName: "repeat_oth_recorded_risks",
                label: "",
                attachmentURLs: [],
                index: 33,
                imageIndex: null
            },
            {
                title: "Electrical pit location",
                tableName: "repeat_electr_pit_markup",
                label: "",
                attachmentURLs: [],
                index: 10,
                imageIndex: null
            },
            {
                title: "Photos of electrical pit (external)",
                tableName: "repeat_photo_ext_electr_pit",
                label: "",
                attachmentURLs: [],
                index: 37,
                imageIndex: null
            },
            {
                title: "Photos of electrical pit (internal)",
                tableName: "repeat_photo_int_electr_pit",
                label: "",
                attachmentURLs: [],
                index: 39,
                imageIndex: null
            },
            {
                title: "Hazardous zones",
                tableName: "repeat_hazardous_zones",
                label: "",
                attachmentURLs: [],
                index: 12,
                imageIndex: null
            },
            {
                title: "Line of sight photos",
                tableName: "repeat_line_of_sight",
                label: "",
                attachmentURLs: [],
                index: 14,
                imageIndex: null
            },
            {
                title: "Data rack",
                tableName: "repeat_data_rack",
                label: "",
                attachmentURLs: [],
                index: 8,
                imageIndex: null
            },
            {
                title: "Photos of proposed equipment",
                tableName: "repeat_photos_equipment",
                label: "",
                attachmentURLs: [],
                index: 43,
                imageIndex: null
            },
            {
                title: "Canopy condition",
                tableName: "repeat_canopy_condition",
                label: "",
                attachmentURLs: [],
                index: 6,
                imageIndex: null
            },
            {
                title: "360 degree site photos",
                tableName: "repeat_site_photos",
                label: "",
                attachmentURLs: [],
                index: 51,
                imageIndex: null
            },
            {
                title: "",
                tableName: "repeat_m_switchb_tenancies",
                label: "",
                attachmentURLs: [],
                index: 18,
                imageIndex: null
            },
            {
                title: "",
                tableName: "repeat_mains_sections",
                label: "",
                attachmentURLs: [],
                index: 19,
                imageIndex: null
            },
            {
                title: "",
                tableName: "repeat_markup_in_m_switchb",
                label: "",
                attachmentURLs: [],
                index: 21,
                imageIndex: null
            },
            {
                title: "Other switchboards",
                tableName: "repeat_oth_existing_switchb",
                label: "",
                attachmentURLs: [],
                index: 31,
                imageIndex: null
            },
            {
                title: "Proposed Equipment Locations",
                tableName: "repeat_proposed_equipment_loc",
                label: "",
                attachmentURLs: [],
                index: 45,
                imageIndex: null
            },
            {
                title: "",
                tableName: "repeat_sub_mains_sections",
                label: "",
                attachmentURLs: [],
                index: 56,
                imageIndex: null
            },
            {
                title: "",
                tableName: "repeat_sub_sm_arrangement",
                label: "",
                attachmentURLs: [],
                index: 58,
                imageIndex: null
            },
        ],
        reportList: [
          "assets/pdfs/Alexandria_Template.pdf",
          "assets/pdfs/Northmead_Template.pdf",
          "assets/pdfs/Carseldine_Template.pdf",
          "assets/pdfs/EdmondsonPark_Template.pdf",
          "assets/pdfs/Tingalpa_Template.pdf",
          "assets/pdfs/AltonaNorth_Template.pdf",
          "assets/pdfs/HawthorneEast_Template.pdf"
        ],
        trancheReportList: [
          "assets/pdfs/Ampol_Report_Epping.pdf",
          "assets/pdfs/Ampol_Report_Morningside.pdf",
          "assets/pdfs/Ampol_Report_Campbellfield.pdf",
          "assets/pdfs/Ampol_Report_ReedyCreekNorthbound.pdf",
          "assets/pdfs/Ampol_Report_Clarkson.pdf",
          "assets/pdfs/Ampol_Report_EastPerth.pdf",
          "assets/pdfs/Ampol_Report_SouthWentworthville.pdf",
          "assets/pdfs/Ampol_Report_Penrith.pdf",
          "assets/pdfs/Ampol_Report_Hazelbrook.pdf",
          "assets/pdfs/Ampol_Report_SippyDowns.pdf",
          "assets/pdfs/Ampol_Report_Hillside.pdf",
          "assets/pdfs/Ampol_Report_Rowville.pdf",
          "assets/pdfs/Ampol_Report_Birtinya.pdf",
          "assets/pdfs/Ampol_Report_MarsdenPark.pdf",
          "assets/pdfs/Ampol_Report_CabooltureTravelCentre.pdf",
          "assets/pdfs/Ampol_Report_RaymondTerraceNorth.pdf",
          "assets/pdfs/Ampol_Report_Charlestown.pdf",
          "assets/pdfs/Ampol_Report_GosfordWest.pdf",
          "assets/pdfs/Ampol_Report_Thornleigh.pdf",
          "assets/pdfs/Ampol_Report_WollongongNorth.pdf",
          "assets/pdfs/Ampol_Report_Greenacre.pdf",
          "assets/pdfs/Ampol_Report_StIves.pdf",
          "assets/pdfs/Ampol_Report_Dural.pdf",
          "assets/pdfs/Ampol_Report_Stratton.pdf",
          "assets/pdfs/Ampol_Report_Gosnells.pdf",
          "assets/pdfs/Ampol_Report_Kilsyth.pdf",
          "assets/pdfs/Ampol_Report_MtWaverley.pdf",
          "assets/pdfs/Ampol_Report_Cheltenham.pdf",
          "assets/pdfs/Ampol_Report_Derrimut.pdf",
          "assets/pdfs/Ampol_Report_MillPark.pdf"
        ],
        tranche3ReportList: [
            "assets/pdfs/Ampol_Report_Sandringham.pdf",
            "assets/pdfs/Ampol_Report_Rydalmere.pdf",
            "assets/pdfs/Ampol_Report_SevenHills.pdf",
            "assets/pdfs/Ampol_Report_Kalkallo.pdf",
            "assets/pdfs/Ampol_Report_Moorebank.pdf",
            "assets/pdfs/Ampol_Report_Keysborough.pdf",
            "assets/pdfs/Ampol_Report_Punchbowl.pdf",
            "assets/pdfs/Ampol_Report_Sandown.pdf",
            "assets/pdfs/Ampol_Report_Ashby.pdf",
            "assets/pdfs/Ampol_Report_Karrinyup.pdf",
            "assets/pdfs/Ampol_Report_WindsorSouth.pdf",
            "assets/pdfs/Ampol_Report_Kogarah.pdf",
            "assets/pdfs/Ampol_Report_BarrackHeights.pdf",
            "assets/pdfs/Ampol_Report_GregoryHills.pdf",
            "assets/pdfs/Ampol_Report_Ambarvale.pdf",
            "assets/pdfs/Ampol_Report_Randwick.pdf",
            "assets/pdfs/Ampol_Report_Byford.pdf",
            "assets/pdfs/Ampol_Report_LilliPilli.pdf",
            "assets/pdfs/Ampol_Report_HeathcoteNb.pdf",
            "assets/pdfs/Ampol_Report_Warnbro.pdf",
            "",
            "assets/pdfs/Ampol_Report_Narangba.pdf",
            "assets/pdfs/Ampol_Report_NorthLakes.pdf",
            "assets/pdfs/Ampol_Report_BelmontNorth.pdf",
            "assets/pdfs/Ampol_Report_Hamilton.pdf",
            "assets/pdfs/Ampol_Report_NorthMaclean.pdf",
            "assets/pdfs/Ampol_Report_WaterfordWest.pdf",
            "",
            "assets/pdfs/Ampol_Report_RutherfordWest.pdf",
            "assets/pdfs/Ampol_Report_Erskine.pdf",
            "assets/pdfs/Ampol_Report_WyongNb.pdf",
            "assets/pdfs/Ampol_Report_ForrestHighwaySouth.pdf",
            "assets/pdfs/Ampol_Report_AranaHills.pdf",
            "assets/pdfs/Ampol_Report_RedbankPlains.pdf",
            "assets/pdfs/Ampol_Report_Wyoming.pdf",
            "assets/pdfs/Ampol_Report_Yunderup.pdf",
            "assets/pdfs/Ampol_Report_Tewantin.pdf",
            "assets/pdfs/Ampol_Report_Holbrook.pdf",
            "assets/pdfs/Ampol_Report_CoffsHarbourSouth.pdf",
            "assets/pdfs/Ampol_Report_RockdalePlaza.pdf",
            "assets/pdfs/Ampol_Report_PacificSquare.pdf",
            "assets/pdfs/Ampol_Report_Campbelltown.pdf",
            "",
            "assets/pdfs/Ampol_Report_Singleton.pdf",
            "assets/pdfs/Ampol_Report_BateauBaySquare.pdf",
        ],
        reportURL: "",
        index: null,
        bookmark: null,
        expandBookmark: null,
      }
    },
    methods: {
      makeID: function(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
      resetSlice: function(newValue, oldValue) {
        const current = this
        current.slice.viewModel.clearSlice()
      },
      initialSetup: function(current) {
        current.prefixId = current.makeID(5)
        esriConfig.portalUrl = current.portalURL
        var authInfo = new OAuthInfo({
          appId: current.appId,
          portalUrl: current.portalURL,
          popup: false
        });
        esriId.registerOAuthInfos([authInfo]);
        var portal = new Portal({
          url: current.portalURL
        });
        let portalItem = new PortalItem({
          id: current.webMapId,
          portal: portal
        });
        portal.authMode = "immediate";
        portal.load().then(function(portalResult) {
          // block to add bespoke functionality for authentication ruleset if ever
          var userData = {
            userName: portalResult.user.username,
            email: portalResult.user.email,
            fullName: portalResult.user.fullName
          }
          // current.$store.commit("mapModule/saveUserName", portalResult.user.username)
          current.$store.commit("mapModule/saveUserEmail", portalResult.user.email)
          current.$store.commit("mapModule/saveUser", userData)
        });
        esriId
        .checkSignInStatus(current.portalURL + "/sharing/rest")
        .then(function (){
          current.webMap = new WebMap({
            portalItem: portalItem
          })
          current.webMap.load()
          .catch((error)=>{
            // error
          })
          .then(()=>{
            current.view = new MapView({
              container: current.prefixId + "-viewDiv",
              map: current.webMap,
              highlightOptions: {
                color: [255, 255, 0, 1],
                haloColor: "white",
                haloOpacity: 0.9,
                fillOpacity: 0.2
              }
            })
            current.view.on("click", evt=>{
              current.view.hitTest(evt).then((hitTestResult)=>{
                current.reportURL = ""
                var validEntries = hitTestResult.results.filter((item)=>{return (item.graphic.geometry != null && ['Ampol Pilot Electrical Checklist','Ampol Tranche 1 Electrical Checklist','Ampol Tranche 3 Electrical Checklist'].includes(item.graphic.layer.title))})
                if (validEntries.length > 0) {
                  let entry = validEntries[0]
                  let serviceId = ""
                  let objectid = entry.graphic.attributes.objectid
                  console.log(entry.graphic.layer.title);
                  console.log(objectid);
                  if (entry.graphic.layer.title == 'Ampol Pilot Electrical Checklist') {
                    current.attachmentPhotos = current.pilotAttachmentPhotos
                    serviceId = "service_1b25380d736245fca423df4e867cb730"
                    current.reportURL = current.reportList[objectid-1]
                  }
                  if (entry.graphic.layer.title == 'Ampol Tranche 1 Electrical Checklist') {
                    current.attachmentPhotos = current.tranche1AttachmentPhotos
                    serviceId = "service_36489c7557e84c5a9edc9e8a029d3c11",
                    current.reportURL = current.trancheReportList[objectid-9]
                  }
                  if (entry.graphic.layer.title == 'Ampol Tranche 3 Electrical Checklist') {
                    current.attachmentPhotos = current.tranche3AttachmentPhotos
                    serviceId = "Ampol_Tranche_3_AGOL_Upload_v2",
                    current.reportURL = current.tranche3ReportList[objectid-1]
                  }
                  current.attachmentPhotos.forEach(item=>{item.attachmentURLs.length = 0})
                  let featureLayer = entry.graphic.layer
                  var token = esriId.credentials[0].token
                  // get attachment of main layer
                  let mainURL = `https://au1gis.solutions.arcadis.com/arcgis/rest/services/Hosted/${serviceId}/FeatureServer/0/${objectid}/attachments?f=pjson&token=${token}`
                  axios.get(mainURL).then(mainRes=>{
                    if (mainRes.data.attachmentInfos.length > 0) {
                      mainRes.data.attachmentInfos.forEach(mainAttachments=>{
                        let mainURL = `https://au1gis.solutions.arcadis.com/arcgis/rest/services/Hosted/${serviceId}/FeatureServer/0/${objectid}/attachments/${mainAttachments.id}?token=${token}`
                        current.attachmentPhotos[0].attachmentURLs.push({
                          title: mainAttachments.name,
                          src: mainURL
                        })
                      })
                    }
                    featureLayer.relationships.forEach(item=>{
                      var query = {
                        outFields: ["*"],
                        relationshipId: item.id,
                        objectIds: [objectid]
                      }

                      featureLayer.queryRelatedFeatures(query).then(relatedFeatures=> {
                        relatedFeatures[objectid].features.forEach(relatedFeature=>{
                          let id = relatedFeature.attributes.objectid
                        //   console.log("feature ID: " + id + ' - ' + objectid);
                          let url = `https://au1gis.solutions.arcadis.com/arcgis/rest/services/Hosted/${serviceId}/FeatureServer/${item.relatedTableId}/${id}/attachments?f=pjson&returnUrl=true&token=${token}`
                          axios.get(url).then(response=>{
                            response.data.attachmentInfos.forEach(attachmentInfo=>{
                              let attachmentURL = `https://au1gis.solutions.arcadis.com/arcgis/rest/services/Hosted/${serviceId}/FeatureServer/${item.relatedTableId}/${id}/attachments/${attachmentInfo.id}?token=${token}`
                            //   TODO: FIX BY filtering the attachment to correctly put the attachments on the correct section

                              let relatedTableIndex = current.attachmentPhotos.findIndex(x=>x.index == item.relatedTableId)
                              current.attachmentPhotos[relatedTableIndex].attachmentURLs.push({
                                title: attachmentInfo.name,
                                src: attachmentURL
                              })
                            })
                          })
                          .catch(err=>{
                            console.log(err);
                          })
                          let relatedFeatureTable = new FeatureLayer(
                            {
                              url: `https://au1gis.solutions.arcadis.com/arcgis/rest/services/Hosted/${serviceId}/FeatureServer/${item.relatedTableId}`
                            }
                          )
                          relatedFeatureTable.load().then((table)=>{
                            let necessaryRelationships = table.relationships.filter(item=>{return (item.role == "origin")})
                            if (necessaryRelationships.length > 0) {
                              necessaryRelationships.forEach(tableItem=>{
                                let tableRelationshipQuery = {
                                  outFields: ["*"],
                                  relationshipId: tableItem.id,
                                  objectIds: [id]
                                }
                                relatedFeatureTable.queryRelatedFeatures(tableRelationshipQuery).then(relatedTableFeatures=>{
                                  relatedTableFeatures[id].features.forEach(tableRelatedFeature=>{
                                    let relatedId = tableRelatedFeature.attributes.objectid
                                    // console.log("feature ID (at related level): " + id + ' - ' + objectid + ' - ' + relatedId);

                                    let relatedUrl = `https://au1gis.solutions.arcadis.com/arcgis/rest/services/Hosted/${serviceId}/FeatureServer/${tableItem.relatedTableId}/${relatedId}/attachments?f=pjson&returnUrl=true&token=${token}`
                                    axios.get(relatedUrl).then(relatedResponse=>{
                                      relatedResponse.data.attachmentInfos.forEach(relatedAttachmentInfo=>{
                                        let relatedAttachmentURL = `https://au1gis.solutions.arcadis.com/arcgis/rest/services/Hosted/${serviceId}/FeatureServer/${tableItem.relatedTableId}/${relatedId}/attachments/${relatedAttachmentInfo.id}?token=${token}`
                                        let relatedTableIndex = current.attachmentPhotos.findIndex(x=>x.index == tableItem.relatedTableId)
                                        current.attachmentPhotos[relatedTableIndex].attachmentURLs.push({
                                          title: relatedAttachmentInfo.name,
                                          src: relatedAttachmentURL
                                        })
                                      })
                                    })
                                    .catch(err=>{
                                      console.log(err);
                                    })
                                  })
                                })
                              })
                            }
                          })
                        })
                      })
                    })
                  })
                }
              })
            })
            current.setupWidgets(current)
          })

        })
        return;
      },
      setupWidgets: function(current) {
          // Top left Widget
        var homeWidget = new Home({
          view: current.view
        });
        current.view.ui.add(homeWidget, {
          position: "top-left",
          index: 0
        });
        // Right hand widgets
        current.search = new Search({
          view: current.view
        });
        current.expandSearch = new Expand({
          view: current.view,
          expandTooltip: "Search",
          collapseTooltip: "Search",
          content: current.search,
        });
        current.layerList = new LayerList({
          view: current.view,
          listItemCreatedFunction: function(event) {
            var item = event.item;
            item.actionsSections = [
              [{
                title: "Zoom to layer",
                className: "fa fa-search",
                id: "full-extent"
              }]
            ]
          }
        });
        current.layerList.on("trigger-action", function(event) {
          current.view.goTo(event.item.layer.fullExtent);
        });
        current.expandLayerList = new Expand({
          view: current.view,
          expandTooltip: "Layer List",
          collapseTooltip: "Layer List",
          content: current.layerList
        });
        var layersInfo = []
        var webMapLayers = current.webMap.layers.toArray();
        webMapLayers.forEach(function(item, i) {
          var layerItem = {}
          layerItem.layer = item
          layerItem.title = item.title
          layersInfo.push(layerItem)
        });
        current.legendList = new Legend({
          view: current.view,
          layersInfos: layersInfo
        });
        current.expandLegend = new Expand({
          view: current.view,
          expandTooltip: "Legend",
          collapseTooltip: "Legend",
          content: current.legendList,
        });
        current.basemapGallery = new BasemapGallery({
          view: current.view
        });
        current.expandBaseMapGallery = new Expand({
          view: current.view,
          expandTooltip: "Basemap Gallery",
          collapseTooltip: "Basemap Gallery",
          content: current.basemapGallery,
        });
        current.bookmark = new Bookmarks({
          view: current.view
        })
        current.expandBookmark = new Expand({
          view: current.view,
          expandTooltip: "Bookmarks",
          collapseTooltip: "Bookmarks",
          content: current.bookmark,
        });
        document.getElementById(current.prefixId + "-viewGalleryDiv").style.display = "block";
        current.viewGalleryWidget = document.getElementById(current.prefixId + "-viewGalleryDiv");

        current.view.ui.add(current.expandSearch,"top-right")
        current.view.ui.add(current.expandLayerList,"top-right")
        current.view.ui.add(current.expandLegend,"top-right")
        current.view.ui.add(current.expandBookmark, "top-right")
        current.view.ui.add(current.expandBaseMapGallery,"top-right")
        // current.view.ui.add(current.expandSlice,"top-right")
        // current.view.ui.add(current.expandMeasurementLine,"top-right")
        // current.view.ui.add(current.expandMeasurementArea,"top-right")
        // current.view.ui.add(current.expandDiscussionTool,"top-right")
        // current.view.ui.add(current.discussionToolRefreshWidget,"top-right")
        // current.view.ui.add(current.discussionToolWidget,"top-right")
        // current.view.ui.add(current.expandScreenshot,"top-right")
        // current.view.ui.add(current.fullscreen,"top-right")
        // current.view.ui.add(current.expandOpacitySliderWidget,"top-right")
        // current.view.ui.add(current.expandLighting,"top-right")
        // current.view.ui.add(current.expandSlide,"top-right")
        // current.view.ui.add(current.faqWidget,"top-right")
        current.view.ui.add(current.viewGalleryWidget,"top-right")
        // current.view.ui.add(current.elevationWidget,"bottom-right")


      },
      setOpacity: function() {
        const current = this
        current.webScene.basemap.baseLayers.items.forEach(function(layer) {
            layer.opacity = 1 -(current.opacityValue / 100)
          });
      },
      screenshotBtnClick: function() {
        const current = this
        var view = current.view
        var screenshotBtn = document.getElementById(current.prefixId + '-screenshotBtn')
        current.expandScreenshot.collapse()
        screenshotBtn.classList.add("active")
        var appMap = document.getElementById(current.prefixId + '-gis2dViewer')
        appMap.style.cursor = "crosshair";
        let area = null;
        let maskArea = null;
        var client = {}
        const dragHandler = current.view.on("drag", function(event) {
          event.stopPropagation();
          if (!client.x) {
            client.x = event.native.clientX
            client.y = event.native.clientY
          }
          if (event.action !== "end") {
            const xmin = current.clamp(
              Math.min(event.origin.x, event.x),
              0,
              view.width
            );
            const xmax = current.clamp(
              Math.max(event.origin.x, event.x),
              0,
              view.width
            );
            const ymin = current.clamp(
              Math.min(event.origin.y, event.y),
              0,
              view.height
            );
            const ymax = current.clamp(
              Math.max(event.origin.y, event.y),
              0,
              view.height
            );
            area = {
              x: xmin,
              y: ymin,
              width: xmax - xmin,
              height: ymax - ymin
            };
            maskArea = {
              x: client.x,
              y: client.y,
              width: xmax - xmin,
              height: ymax - ymin
            }
            current.setMaskPosition(maskArea);
          } else {
            dragHandler.remove();
            view
              .takeScreenshot({
                area: area,
                format: "png"
              })
              .then(function(screenshot) {
                current.showPreview(screenshot);

                document.getElementById(current.prefixId + '-downloadBtn').onclick = function() {
                  const text = document.getElementById(current.prefixId + '-textInput').value;
                  if (text) {
                    const dataUrl = current.getImageWithText(screenshot, text);
                    current.downloadImage(
                      current.webmap.portalItem.title + ".png",
                      dataUrl
                    );
                  } else {
                    current.downloadImage(
                      current.webmap.portalItem.title + ".png",
                      screenshot.dataUrl
                    );
                  }
                };

                screenshotBtn.classList.remove("active");
                appMap.style.cursor = "auto";
                current.setMaskPosition(null);
              });
          }
        });
      },
      clamp: function(value, from, to) {
        return value < from ? from : value > to ? to : value;
      },
      setMaskPosition: function(area) {
        const current = this
        var maskDiv = document.getElementById(current.prefixId + '-maskDiv')
        if (area) {
          maskDiv.classList.remove("hide");
          maskDiv.style.left = area.x + "px";
          maskDiv.style.top = area.y + "px";
          maskDiv.style.width = area.width + "px";
          maskDiv.style.height = area.height + "px";
        } else {
          maskDiv.classList.add("hide");
        }
      },
      showPreview: function(screenshot) {
        const current = this
        const screenshotDiv = document.getElementById(current.prefixId + "-screenshotDiv");
        screenshotDiv.classList.remove("hide");
        const screenshotImage = document.getElementById(current.prefixId + "-screenshotImage");
        screenshotImage.width = screenshot.data.width;
        screenshotImage.height = screenshot.data.height;
        screenshotImage.src = screenshot.dataUrl;
      },
      getImageWithText: function(screenshot, text) {
        const imageData = screenshot.data;
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = imageData.height;
        canvas.width = imageData.width;

        context.putImageData(imageData, 0, 0);
        context.font = "20px Arial";
        context.fillStyle = "#000";
        context.fillRect(
          0,
          imageData.height - 40,
          context.measureText(text).width + 20,
          30
        );

        context.fillStyle = "#fff";
        context.fillText(text, 10, imageData.height - 20);

        return canvas.toDataURL();
      },
      downloadImage: function(filename, dataUrl) {
        if (!window.navigator.msSaveOrOpenBlob) {
          const element = document.createElement("a");
          element.setAttribute("href", dataUrl);
          element.setAttribute("download", filename);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        } else {
          const byteString = atob(dataUrl.split(",")[1]);
          const mimeString = dataUrl
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ab], {
            type: mimeString
          });
          window.navigator.msSaveOrOpenBlob(blob, filename);
        }
      },
      screenshotCloseClick: function() {
        const current = this
        const screenshotDiv = document.getElementById(current.prefixId + '-screenshotDiv')
        screenshotDiv.classList.add("hide");
      },
      updateElevation: function(event) {
        const current = this
        current.webScene.ground.layers.forEach(function(layer) {
          layer.visible = event.target.checked;
        });
      },
      updateTimeOfDay: function(event) {
        const current = this
        var select = event.target;
        var optionValue = select.options[select.selectedIndex].value;
        var date = new Date()
        if (optionValue == 1) {
          date.setUTCHours(1);
        }
        if (optionValue == 2) {
          date.setUTCHours(4);
        }
        if (optionValue == 3) {
          date.setUTCHours(8);
        }
        if (optionValue == 4) {
          date.setUTCHours(10);
        }
        date.setUTCMinutes(0);
        date.setUTCSeconds(0);
        current.view.environment.lighting.date = date;
      },
      updateDirectShadows: function(event) {
        const current = this
        current.view.environment.lighting.directShadowsEnabled = !!event.target.checked;
      },
      createSlideUI: function(slide, placement) {
        const current = this
        var slideElement = document.createElement("div");
        slideElement.id = slide.id;
        slideElement.classList.add("slide");

        var slidesDiv = document.getElementById(current.prefixId + "-slidesDiv");
        if (placement === "first") {
          slidesDiv.insertBefore(slideElement, slidesDiv.firstChild);
        } else {
          slidesDiv.appendChild(slideElement);
        }

        var img = new Image();
        img.src = slide.thumbnail.url;
        img.title = slide.title.text;
        slideElement.appendChild(img);
        var title = document.createElement("div");
        title.innerText = slide.title.text;
        slideElement.appendChild(title);

        slideElement.addEventListener("click", function() {
          var slides = document.querySelectorAll(".slide");
          Array.from(slides).forEach(function(node) {
            node.classList.remove("active");
          });

          slideElement.classList.add("active");
          slide.applyTo(current.view);
        });
      },
      openDiscussionToolFullView: function () {
          window.open(appBaseUrl, "_blank");
      },
      refreshDiscussions: function() {
        const current = this
        current.view.map.remove(current.discussionLayer);
        current.view.map.allLayers.refresh();
        // current.$store.dispatch("mapModule/fetchDiscussionLayer").then(result=>{
        //   current.discussionLayer = result
        //   current.view.map.add(current.discussionLayer)
        // })
      },
    },
    mounted() {
      const current = this
      current.fullHeight = Math.round(window.innerHeight / (100 / 100))
      current.clientCanvas.height = this.$refs.gis.clientHeight
      current.clientCanvas.width = this.$refs.gis.clientWidth
      current.initialSetup(current)
    }
  }
</script>
<style scoped>
  .gis2DViewer {
    /* height: 98vh !important;
    width: 98vw !important; */
    height: 100%;
    width: 100%;
  }
  .viewDiv2D {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
  }
  .screenshotDiv {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
  }
  .hide {
    display: none;
  }
  .active {
    background: #0079c1;
    color: #e4e4e4;
  }
  .screenshotCursor {
    cursor: crosshair;
  }
  .screenshotBtnDiv {
    padding: 10px;
    width: 260px;
    bottom: 15px;
    right: 10px;
    font-size: 14px;
    /* display: none; */
  }
  .maskDiv {
    position: absolute;
    background: rgba(255, 51, 0, 0.1);
    border: 2px dashed rgb(255, 51, 0);
  }
  .screenshotDiv > * {
    margin: 0.5em;
  }
  .elevationDiv {
    font-size: 12pt;
    padding: 10px;
    display: none;
    z-index: 1;
  }
  .environmentDiv {
    padding: 10px;
    width: 260px;
    display: none;
  }
  .discussionDiv {
    width: 400px;
    display: none;
  }
  .custom-faq-widget {
    z-index: 1;
    display: none;
    padding: 0.175rem 0.45rem;
  }
  .discussionToolFullView {
    z-index: 1;
    display: none;
    padding: 0.175rem 0.45rem;
  }
  .discussionToolRefresh {
    z-index: 1;
    display: none;
    padding: 0.175rem 0.45rem;
  }
  .slidesDiv {
    position: fixed;
    opacity: 0.9;
    padding-right: 10px;
    visibility: hidden;
    /* bottom: 15px; */
    left: 0;
    right: 0;
    text-align: center;
    /* width: 100vw; */
    max-width:100%;
    display: none;
    overflow-x: auto;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.2);
  }

  div.scrollmenu {
    background-color: #333;
    /* overflow: auto; */
    overflow-x: auto;
    white-space: nowrap;
  }

  div .slidesDiv{
    display: inline-block !important;
    color: white;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 12px;
  }

  .slidesDiv::-webkit-scrollbar-track
  {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #333;
  }

  .slidesDiv::-webkit-scrollbar
  {
    width: 2px !important;
    background-color: #333;
  }

  .slidesDiv::-webkit-scrollbar-thumb
  {
    background-color: #6c757d;
    /* border: 2px solid #555555; */
  }
  /* Ground Transparency Widget */
  .opacitySliderDiv {
    padding: 10px;
    display: none;
    width: 250px;
  }
  /*Chrome*/
  @media screen and (-webkit-min-device-pixel-ratio:0) {
      input[type='range'] {
        overflow: hidden;
        width: 150px;
        -webkit-appearance: none;
        background-color: #E66200;
        border-radius: 8px;
      }

      input[type='range']::-webkit-slider-runnable-track {
        height: 15px;
        -webkit-appearance: none;
        color: #13bba4;
        margin-top: -1px;
      }

      input[type='range']::-webkit-slider-thumb {
        width: 15px;
        -webkit-appearance: none;
        height: 15px;
        cursor: ew-resize;
        background: #434343;
        box-shadow: -150px 0 0 150px #ffe2cc;
      }

  }
  /** FF*/
  input[type="range"]::-moz-range-progress {
    background-color: #ffe2cc;
  }
  input[type="range"]::-moz-range-track {
    background-color: #E66200;
  }
  /* IE*/
  input[type="range"]::-ms-fill-lower {
    background-color: #ffe2cc;
  }
  input[type="range"]::-ms-fill-upper {
    background-color: #E66200;
  }

</style>
<style scoped>
  .slide .active {
    background-color: rgba(0, 121, 193, 0.5);
  }
  .slidesDiv .slide {
    cursor: pointer;
    margin-bottom: 6px;
    display: inline-block;
    padding-right: 10px;
    padding-left: 10px
  }

  .slidesDiv .slide .title {
    text-align: center;
  }

  .slidesDiv .slide.active img {
    box-shadow: 0px 0px 12px black;
    border-style: solid;
    border-width: thin;
    border-color: black;
  }
  .esri-icon-non-visible::before {
  content: "\e610";
  }
  .esri-icon-visible::before {
    content: "\e611";
  }
  .thumb {
      display: inline-block;
      margin: 10px;
      cursor: pointer;
      overflow: hidden;
      border-radius: 3px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    }
  .ul-thumb {
    list-style: none;
    padding: 0;
    margin: 40px 0 0 0;
  }
  .gallerySection {
    height: 20vh;
  }
/* .images-wrapper .image-wrapper .image {
    cursor: pointer;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 100%;
}
.images-wrapper .image-wrapper {
    margin-bottom: 10px;
    width: calc(50% - 10px);
    margin-right: 10px;
} */
.images-wrapper {
  display: flex;
}
.image {
  cursor: pointer;
  height: 200px;
  width: 200px;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
