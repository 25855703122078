<template>
  <div>test</div>
</template>
<script>
/*eslint-disable*/
export default {
  name: 'Header'
}
</script>
<style scoped>

</style>
