<template>
  <div>test</div>
</template>
<script>
/*eslint-disable*/
export default {
  name: 'Footer',
}
</script>
<style scoped>

</style>