<template>
  <div id="app">
    <!-- <div style="width: 100vw; height: 100vh;">
      <GIS3DViewer appId="d3loGAfiQMl39K9y" webSceneId="7057a63713134d799afa23133dcf45d5"/>
    </div> -->
    <!-- <Header id="mainHeader"></Header> -->
    <Body id="mainBody"></Body>
    <!-- <Footer id="mainFooter"></Footer> -->
    <notifications group="errors" classes="vue-notification text-dark" :reverse="true"/>
    <notifications group="successNotifications" classes="vue-notification text-dark" :reverse="true"/>
  </div>
</template>

<script>
/*eslint-disable*/
import Vue from "vue";
import Vuex from "vuex";

import GIS3DViewer from './components/GIS3DViewer.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Body from './components/Body.vue'

import mapStateModule from "./state/modules/mapStateModule.js"

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    mapModule: mapStateModule,
  },
  strict: debug
})

export default {
  name: 'App',
  components: {
    GIS3DViewer,
    Header,
    Body,
    Footer
  },
  store:store,
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
#mainHeader {
  height: 0vh;
  width: 100vw;
  background-color: rgb(75, 59, 59);
}
#mainBody {
  height: 100vh!important;
  width: 100vw;
  background-color: gray;
}
#mainFooter {
  height: 0vh;
  width: 100vw;
  background-color: rgb(48, 19, 11);
}
</style>
