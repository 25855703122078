<template>
  <div id="main">
    <GIS2DViewer appId="zOPriMbJiAXAsXRj" webMapId="def9f126c4c34acdb34f3a410bd94bcc"/>
  </div>
</template>
<script>
/*eslint-disable*/
import GIS2DViewer from './GIS2DViewer.vue'
export default {
  name: 'Body',
  components: {
    GIS2DViewer
  },
}
</script>
<style scoped>
#main {
  background-color: blue;
}
</style>
